import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import ReviewSection from "../../components/review-section";
import TreatmentHero from "../../components/treatment-hero";
import TreatmentCard from "../../components/treatment-card";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";
import uuid4 from "uuid4";

const GeneralDenistryPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
      }
      schemaLogo: wpMediaItem(title: { eq: "dark-logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "family-dentistry" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        familyDentistExeterFieldGroups {
          familyDentistIntroSection {
            heading
            subHeading
            title
            subtitle
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          familyDentistReviewSection {
            heading
            subHeading
          }
          familyDentistWhatWeDoSection {
            heading
            subHeading
            cta {
              target
              title
              url
            }
            items {
              heading
              price
              content
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, familyDentistExeterFieldGroups, slug },
  } = data;

  const {
    familyDentistIntroSection,
    familyDentistReviewSection,
    familyDentistWhatWeDoSection,
  } = familyDentistExeterFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id":
      `https://www.dentalhouseexeter.co.uk/treatments/${slug}/#${slug}`,
    image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
    sku: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
    description: `${seoFieldGroups?.metaDescription}`,
    logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
    name: "Dental House Exeter",
    url: "https://www.dentalhouseexeter.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Dental House Exeter",
      url: "https://www.dentalhouseexeter.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.dentalhouseexeter.co.uk/reviews",
      datePublished: "2022-03-21T17:58:29+00:00",
      reviewBody:
        "This is the best dental practice I have known. Not only in terms of expertise but the lovely sociability of every single member of the practice team.",
      author: {
        "@type": "Person",
        name: "Caroline P",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Dental House Exeter",
        sameAs: "https://www.dentalhouseexeter.co.uk",
      },
    },
  };


  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Treatments",
        item: {
          url: `${siteUrl}/treatments`,
          id: `${siteUrl}/treatments`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Family Dentistry",
        item: {
          url: `${siteUrl}/treatments/family-dentistry`,
          id: `${siteUrl}/treatments/family-dentistry`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/family-dentistry`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {familyDentistIntroSection &&
          !checkPropertiesForNull(familyDentistIntroSection, ["heading"]) && (
            <TreatmentHero
              smallHeadingTopText={familyDentistIntroSection?.subHeading}
              img={
                familyDentistIntroSection?.image.node?.localFile.childImageSharp
                  .gatsbyImageData
              }
              imgAlt={familyDentistIntroSection?.image.node?.altText}
              heading1={familyDentistIntroSection?.heading}
              headingSmall={familyDentistIntroSection?.subtitle}
              heading2={familyDentistIntroSection?.title}
              text={
                <SafeHtmlParser
                  htmlContent={familyDentistIntroSection?.description}
                />
              }
              ctaButton={familyDentistIntroSection?.cta}
            />
          )}
        <ReviewSection
          heading={
            familyDentistReviewSection?.heading ||
            "Reviews From Our Dentistry Patients"
          }
          smallheading={
            familyDentistReviewSection?.subHeading || "WHAT PEOPLE SAY ABOUT US"
          }
          page="General dentistry"
        />
        {familyDentistWhatWeDoSection &&
          !checkPropertiesForNull(familyDentistWhatWeDoSection, [
            "heading",
          ]) && (
            <section className="pt-8 pb-6">
              <Container>
                <Row>
                  <Col className="text-center">
                    <p className="text-accent jost-bold fs-6">
                      {familyDentistWhatWeDoSection?.subHeading}
                    </p>
                    <h2 className="text-text-secondary display-4 pb-4">
                      {familyDentistWhatWeDoSection?.heading}
                    </h2>
                  </Col>
                </Row>
                {familyDentistWhatWeDoSection.items &&
                  familyDentistWhatWeDoSection.items.length > 0 && (
                    <Row className="pt-md-8 pt-5 h-100 justify-content-center">
                      {" "}
                      {familyDentistWhatWeDoSection.items.map((cardItem) => (
                        <TreatmentCard
                          key={uuid4()}
                          image={
                            cardItem?.image.node?.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          imgAlt={cardItem?.image.node?.altText}
                          title={cardItem?.heading}
                          text={
                            <SafeHtmlParser htmlContent={cardItem?.content} />
                          }
                          span={4}
                          price={cardItem?.price}
                          shorter="image-shorter"
                        />
                      ))}
                    </Row>
                  )}
                  {familyDentistWhatWeDoSection.cta &&
                familyDentistWhatWeDoSection.cta.url && (
                  <Row>
                    <Col className="text-center mt-md-6">
                      {" "}
                      <Button
                        className="text-white py-3 px-5 w-100 w-md-auto"
                        variant="accent"
                        href={familyDentistWhatWeDoSection.cta?.url}
                        target={
                          familyDentistWhatWeDoSection.cta?.target ?? "_blank"
                        }
                        rel="noreferrer"
                      >
                        {familyDentistWhatWeDoSection.cta?.title}
                      </Button>
                    </Col>
                  </Row>
                )}
              </Container>
            </section>
          )}
        {/* <TreatmentImageSection
					img={wc1Img}
					imgAlt1={data.wc1Img?.altText}
					img2={wc2Img}
					imgAlt2={data.wc2Img?.altText}
					heading="Why choose Dental House Exeter for general denistry?"
					text={
						<span className="text-white">
							<p className="text-white jost-medium">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi
								diam, ut nisi, suspendisse tortor, morbi gravida in. Erat lorem
								semper erat sed eget ac morbi parturient. Nisi magna cursus
								vulputate odio sed viverra.{" "}
							</p>
							<p className="text-white jost-medium">
								Lectus enim at amet diam. Morbi diam suscipit luctus amet
								dignissim. Velit donec tortor non eget curabitur at hendrerit
								nunc, nulla. Ut aliquam ut eget orci. At at varius vestibulum
								sagittis libero. Maecenas ornare nunc magna enim id convallis
								vel dis pellentesque. Ultrices nulla lacinia nulla adipiscing
								sit viverra at. Non elementum, orci, odio tortor arcu aliquam
								cursus tortor augue. Curabitur montes, id id non eget. Nulla
								semper aliquet sed elementum morbi diam est quis. Leo nec, ac
								massa eleifend molestie fringilla. Nibh eget augue et rutrum
								praesent quisque pharetra. Sed integer odio egestas viverra.
								Proin ultrices proin tellus elementum mi velit, at. Et lobortis
								eget habitasse volutpat. At dui nibh arcu pellentesque. Quis
								ultrices sed proin vitae nisl nec commodo, tincidunt in. Quam
								quis nulla in semper mollis. Felis in nibh sit vitae, semper
								augue. Neque urna, ullamcorper sed pulvinar. Lorem id lacus vel
								ullamcorper viverra massa, commodo. Ornare egestas habitasse
								duis nibh scelerisque urna malesuada aenean.
							</p>
						</span>
					}
					headingSmall="GENERAL DENTISTS"
				/> */}
      </Layout>
    </>
  );
};

export default GeneralDenistryPage;
